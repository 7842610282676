<template>
  <div class="home">
    <v-layout row wrap>
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
          <router-link to="/">Dashboard</router-link> / List Helicopter
        </h1>
      </v-flex>
      <v-flex xs12 lg12>
        <v-flex xs12 md12 class="pa-5">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left uppercase primary--text">
                    Ac Unique Code
                  </th>
                  <th class="text-left uppercase primary--text">Engine</th>
                  <th class="text-left uppercase primary--text">Yr. Reg</th>
                  <th class="text-left uppercase primary--text">Date UAE Reg.</th>
                  <th class="text-left uppercase primary--text">Lease Type</th>
                  <th class="text-left uppercase primary--text">Lease Currency</th>
                  <th class="text-left uppercase primary--text">Total Lease Value</th>
                  <th class="text-left uppercase primary--text">Mo. Lease Due dt.</th>
                  <th class="text-left uppercase primary--text">Rate/hr.</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in copters" :key="item._id">
                  <td>{{ item.code }}</td>
                  <td>{{ item.engine }}</td>
                  <td>{{ item.year_of_registration}}</td>
                  <td>{{ item.date_uae_registration }}</td>
                  <td>{{ item.lease_type }}
                  </td>
                  <td>{{ item.lease_currency }}
                  </td>
                  <td>{{ item.total_lease_value }}</td>
                  <td>{{ item.monthly_lease_due_date }}</td>
                  <td>{{ item.rate_per_hour }}</td> 
                  <td><v-btn @click="viewHelicopter(item._id)"><v-icon>mdi-magnify</v-icon></v-btn></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-flex>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import Axios from "axios";
import { BASE_URL } from "@/config";
export default {
  data() {
    return {
      copters: [],
      searchText: "",
    };
  },
  mounted() {
    this.fetchHelicopters();
  },
  computed: {
    filteredHelicopters() {
      const query = this.searchText.toLowerCase();
      if (query.length >= 3) {
        return this.copters.filter((item) =>
          JSON.stringify(item).toLowerCase().includes(query)
        );
      } else {
        return this.copters;
      }
    },
  },
  methods: {
    async fetchHelicopters() {
      this.$store.dispatch("SET_PROGRESS",true)
      let url = BASE_URL + "/helicopter";
      let { data } = await Axios.get(url);
      this.copters = data;
      this.$store.dispatch("SET_PROGRESS",false)
    },
    viewHelicopter(id) {
      this.$router.push({ name: "ViewHelicopter", params: { id: id } });
    },
  },
};
</script>